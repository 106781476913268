const TYPES = {
  FETCH_TODO: 'FETCH_TODO',
  FETCH_TODO_SUCCESS: "FETCH_TODO_SUCCESS",
  FETCH_TODO_ERROR: "FETCH_TODO_ERROR",
  ADD_TODO: 'ADD_TODO',
  REMOVE_TODO: 'REMOVE_TODO',
  TOGGLE_TODO: 'TOGGLE_TODO'
};

export default TYPES;
